.load-umecube {
  width: 6em;
  height: 6em;
  margin: 0 auto;
  position: relative;

  &.app-loader {
    font-size: 1.25em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  & .load-cube {
    color: white;
    position: absolute;
    width: 50%;
    height: 50%;
    text-align: center;
    transform: scale(1.01);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $logo-blue;
      animation: load-fold-cube 2.15s infinite linear both;
      transform-origin: 0 100%;
    }

    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-size: 1.5em;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      animation: load-letter 2.15s infinite linear both;
      transform-origin: 100% 0;
    }

    &.load-l1,
    &.load-l2,
    &.load-l3,
    &.load-l4 {
      &:before {
        display: none;
      }
    }
  }

  & .load-c1,
  & .load-l1 {
    left: 0;
    top: 0;
  }

  & .load-c2,
  & .load-l2 {
    left: 50%;
    top: 0;
  }

  & .load-c3,
  & .load-l3 {
    left: 50%;
    top: 50%;
  }

  & .load-c4,
  & .load-l4 {
    left: 0;
    top: 50%;
  }

  // animation order
  & .load-c2 {
    &:before {
      background-color: white;
      border: 1px solid $logo-blue;
      animation-delay: 1.1s;
    }
  }

  & .load-l2 {
    transform: scale(1.01);
  }

  & .load-c1 {
    transform: scale(1.01) rotateZ(-90deg);
  }

  & .load-l1 {
    transform: scale(1.01);
  }

  & .load-c4 {
    transform: scale(1.01) rotateZ(180deg);

    &:before {
      animation-delay: 0.35s;
    }
  }

  & .load-l4 {
    transform: scale(1.01);

    & > span {
      animation-delay: 0.4s;
    }
  }

  & .load-c3 {
    transform: scale(1.01) rotateZ(90deg);

    &:before {
      animation-delay: 0.7s;
    }
  }

  & .load-l3 {
    transform: scale(1.01);

    & > span {
      animation-delay: 0.75s;
    }
  }
}

@keyframes load-fold-cube {
  0%,
  10% {
    transform: perspective(15em) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(15em) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(15em) rotateY(-180deg);
    opacity: 0;
  }
}

@keyframes load-letter {
  0%,
  10% {
    transform: rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  85%,
  100% {
    transform: rotateY(180deg);
    opacity: 0;
  }
}
