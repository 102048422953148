@import "src/assets/styles/variables";

.mdc-button,
.mat-mdc-raised-button,
.mat-mdc-flat-button,
.mat-mdc-outlined-button,
.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mdc-text-button-label-text-transform:      uppercase;
  --mdc-outlined-button-label-text-transform:  uppercase;
  --mdc-filled-button-label-text-transform:    uppercase;
  --mdc-protected-button-label-text-transform: uppercase;

  &.btn_lg {
    font-size: 1.25em;
  }

  &.btn_sm {
    font-size: 0.75em;
  }

  &.full-width {
    width: 100%;
  }

  &.mid-width {
    min-width: 166px;
  }

  &.content-between {
    .mdc-button-wrapper {
      display:         flex;
      justify-content: space-between;
      align-items:     center;
    }
  }
}

a.mdc-button--unelevated {
  text-decoration: none;
}

.mdc-button,
.mat-mdc-outlined-button,
.mat-mdc-raised-button {
  .mat-spinner {
    position: absolute;
    left:     calc(50% - 11px);
    top:      calc(50% - 11px);
    opacity:  .5;
  }
}

.mat-mdc-outlined-button {
  &:not(:disabled) {
    --mdc-outlined-button-outline-color: var(--mat-outlined-button-state-layer-color)
  }

  &.dashed {
    border-style: dashed;

    &.primary {
      &:hover,
      &:focus {
        border-style: solid;
        border-color: $brand-primary;
        color:        $brand-primary;
      }
    }
  }
}

a[class*="mat-button"] {
  &.mdc-button-disabled {
    pointer-events: none;
  }
}

// START caution button style
// If we need yellow backgroud and white text 

.mdc-button.caution-btn,
.mat-mdc-flat-button.caution-btn,
.mat-mdc-raised-button.caution-btn,
.mat-mdc-fab.caution-btn,
.mat-mdc-mini-fab.caution-btn,
.mat-mdc-outlined-button.caution-btn {
  &:not(.mat-mdc-button-disabled) {
    background-color: $brand-caution;
    color:            $white;
  }
}

// If we need white background and yellow text
.mdc-button.caution-text-btn,
.mat-mdc-flat-button.caution-text-btn,
.mat-mdc-raised-button.caution-text-btn,
.mat-mdc-fab.caution-text-btn,
.mat-mdc-mini-fab.caution-text-btn,
.mat-mdc-outlined-button.caution-text-btn,
.mat-mdc-icon-button.caution-text-btn {
  &:not(.mat-mdc-button-disabled) {
    background-color: $white;
    color:            $brand-caution;
  }
}

// END caution button style
.mat-mdc-icon-button {
  &.mat-mdc-icon-button-sm {
    width:       32px;
    height:      32px;
    line-height: 32px;

    i.mat-icon-sm,
    .mat-icon-sm {
      line-height: $icon-sm;
    }
  }
}