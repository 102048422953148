@import "src/assets/styles/variables";

.mat-mdc-menu-panel {
  width: 360px;
  &.custom-mat-menu {
    max-width: 360px !important;
    width:     340px;
    padding:   $content-gap-half $content-gap;
  }

  &.fit-size-menu {
    width:     fit-content;
    padding:   $content-gap-half $content-gap-quoter;
  }

  &.sm-size-menu {
    width:   240px;
    padding: $content-gap-half $content-gap;
  }

  &.mid-size-menu {
    max-width: 300px !important;
    width:     max-content;
    padding:   $content-gap-half $content-gap;
  }

  &.mat-menu-xl {
    width:     400px;
    max-width: 400px !important;
    padding:   $content-gap-half $content-gap;

    .mdc-button {
      display: block !important;
    }
  }

  &.mat-menu-xxl {
    width:     650px;
    max-width: 700px !important;
    padding:   $content-gap-half $content-gap;

    .mdc-button {
      display: block !important;
    }
  }
}

a.mat-menu-item {
  &:focus {
    outline: none;
  }
}

.mat-mdc-option {
  font-size: $fs-md;
}

.mat-mdc-autocomplete-panel {
  &.mat-menu-xl {
    width:     400px;
    max-width: 400px !important;
    padding:   $content-gap-half $content-gap;

    .mdc-button{
      display: block !important;
    }
  }
}
