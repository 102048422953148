@import "src/assets/styles/variables";

.mat-icon {
  &.mat-icon-sm {
    font-size: $icon-sm;
    width:     $icon-sm;
    height:    $icon-sm;

    & + .mat-icon {
      margin-left: $content-gap-half;
    }
  }
}

.mat-mdc-button > .mat-icon {
  height:    1.5rem !important;
  width:     1.5rem !important;
  font-size: 1.5rem !important;
}
