@import "src/assets/styles/variables";

.mat-mdc-card, .mdc-card {
  padding: $content-gap;

  .mat-mdc-card-title {
    font-size: $h2-font-size;
    font-weight: $headings-font-weight;
    margin-bottom: $content-gap-half;
  }

  .mat-mdc-card-content {
    padding: 0;

    &:last-child{
      padding-bottom: 0;
    }
  }

  & .mat-mdc-card-subtitle {
    &:empty {
      display: none;
    }
  }

  & .mat-mdc-card-actions,
  & .mat-mdc-card-footer {
    &:empty {
      display: none;
    }
  }
}
