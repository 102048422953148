@import "../variables";
@import "../mixins/mixins";

.hidden {
  display: none !important;
}

.no-pointer-events {
  cursor:         auto;
  pointer-events: none;
}

.pointer-events {
  cursor:         auto;
  pointer-events: all;
}

.ume-scroll {
  @include customScroll(6px, $brand-primary, $brand-primary);
}

.img-round {
  border-radius: 50%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.h-100 {
  height: 100%;
}

.m {
  &-t {
    margin-top: $content-gap;

    &-0 {
      margin-bottom: 0;
    }
  }

  &-b {
    margin-bottom: $content-gap;

    &-0 {
      margin-bottom: 0;
    }

    &-half {
      margin-bottom: $content-gap-half;
    }
  }

  &-l {
    margin-left: $content-gap;
  }

  &-r {
    margin-right: $content-gap;
  }
}

.n-m {
  margin: 0;
}

.p {
  &-t {
    padding-top: $content-gap;
  }

  &-b {
    padding-bottom: $content-gap;
  }

  &-l {
    padding-left: $content-gap;
  }

  &-r {
    padding-right: $content-gap;
  }
}

.n-p {
  padding: 0;
}

.relative {
  position: relative;
}

// flex patterns
.flex {
  display:   flex;
  flex-flow: row wrap;

  &.flex-column {
    flex-direction: column;
    flex-flow:      column;
  }

  &.nowrap,
  &.flex-nowrap {
    flex-wrap: nowrap;
  }

  &.grow-0,
  & .grow-0 {
    flex-grow: 0;
  }

  &.grow-1,
  & .grow-1 {
    flex-grow: 1;
  }

  &.expand-center {
    justify-content: space-around;
  }

  &.expand {
    align-content:   space-between;
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }
  
  &.flex-gap {
    gap: $content-gap;
  }

  &.flex-half-gap {
    gap: $content-gap-half;
  }

  &.flex-gap-md {
    gap: $content-gap;
  }

  &.flex-double-gap {
    gap: $content-gap*2;
  }
}

.flex-align-center {
  align-items: center;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

// text
.font-bold {
  font-weight: map-get($font-weight-values, bold);
}

.font-medium {
  font-weight: map-get($font-weight-values, medium);
}

.font-regular {
  font-weight: map-get($font-weight-values, regular);
}

.nowrap {
  white-space: nowrap;
}

.ellipsis {
  overflow:      hidden;
  text-overflow: ellipsis;
  white-space:   nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.link-like {
  color:                    $brand-primary-dark;
  cursor:                   pointer;
  text-decoration:          underline;
  text-decoration-skip-ink: auto;

  &:active,
  &:hover {
    outline: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// Alignment
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-v-center {
  vertical-align: middle;
}

// Transformation
.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.first-letter-upperCase {
  &:first-letter {
    text-transform: capitalize;
    display:        inline-block;
  }
}

// Contextual colors

.red {
  color: $red;
}

.green {
  color: $green;
}

.yellow {
  color: $yellow;
}

.gray {
  color: $gray;
}

.blue {
  color: $mainBlue;
}

.black {
  color: $black
}

.info {
  color: $brand-primary;
}

.ok {
  color: $brand-success !important;
}

.warn {
  color: $brand-warning !important;
}

.bad {
  color: $brand-danger !important;
}

.default {
  color: $gray-light;
}

.text-default {
  color: $body-color;
}

.text-white {
  color: white;
}

.text-muted {
  color: $gray-lighter;
}

.text-primary {
  color: $brand-primary-dark;
}

.text-success {
  color: $brand-success;
}

.text-info {
  color: $brand-primary;
}

.text-warning {
  color: $brand-warning;
}

.text-caution {
  color: $brand-caution;
}

.text-danger {
  color: $brand-danger;
}

.text-purple {
  color: $purple;
}

// Contextual backgrounds
.bg-white {
  background-color: white;
}

.bg-default {
  background-color: $gray-light;
}

.bg-primary {
  color:            #ffffff;
  background-color: $brand-primary;
}

.bg-success {
  background-color: $brand-success;
}

.bg-info {
  background-color: $brand-primary;
}

.bg-warning {
  background-color: $brand-warning;
}

.bg-caution {
  background-color: $brand-caution;
}

.bg-danger {
  background-color: $brand-danger;
}

// colors
.dark {
  background-color: #eeeeee;
}

.scoring {
  color: #ffffff;
}

.bg-info {
  background-color: $brand-primary;
}

.bg-ok {
  background-color: $brand-success;
}

.bg-warn {
  background-color: $yellow;
}

.bg-bad {
  background-color: $brand-danger;
}

.LOW {
  color: $brand-success;
}

.HIGH {
  color: $brand-danger;
}

.MEDIUM {
  color: map-get($ume-color-palette, yellow-500);
}

.mat-elevation-z00 {
  box-shadow: none;
}

.no-width-limit {
  max-width: none !important;
  width:     auto;
}

.blue-highlight {
  color:       $notification-main-color;
  font-weight: 500;
}

// @media queries
@include screen-max($md) {
  .hide-upTo-md {
    display: none;
  }
}

@include screen-max($lg) {
  .hide-upTo-lg {
    display: none;
  }
}

@include screen-max($lg_) {
  .hide-upTo-lg_ {
    display: none;
  }
}

@include screen-max($xl) {
  .hide-upTo-xl {
    display: none;
  }
}

@include screen-max($xxl) {
  .hide-upTo-xxl {
    display: none;
  }
}

@include screen-max($xxxl) {
  .hide-upTo-xxxl {
    display: none;
  }
}

@include screen($md) {
  .hide-from-md {
    display: none;
  }
}

@include screen($lg) {
  .hide-from-lg {
    display: none;
  }
}

@include screen($lg_) {
  .hide-from-lg_ {
    display: none;
  }
}

@include screen($xl) {
  .hide-from-xl {
    display: none;
  }

  .s-title-hide-above-1200 {
    display: none;
  }
}

@include screen($xxl) {
  .hide-from-xxl {
    display: none;
  }
}

@include screen($xxxl) {
  .hide-from-xxxl {
    display: none;
  }

  .s-title-hide-above-1600 {
    display: none;
  }
}
