@import "src/assets/styles/variables";

.mat-mdc-checkbox {
  &.label-wrap {
    .mat-checkbox-label {
      white-space: normal;
    }
  }

  &.mat-warn {
    .mat-checkbox-frame {
      border-color: rgba(216, 27, 96, 0.54);
    }
  }
}

.ume-dark-theme {
  & .mat-mdc-checkbox {
    .mat-mdc-checkbox-label {
      color: $white;
    }
  }
}

.mat-mdc-checkbox.mat-accent,
.mat-mdc-checkbox.mat-primary,
.mat-mdc-checkbox.mat-warm {
  --mdc-checkbox-selected-checkmark-color: white;
}