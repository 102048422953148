@import "src/assets/styles/ghost-animation";

.ghost-line {
  width:  100%;
  height: 18px;
  @include background-gradient;
  @include animate-ghost-line;

  & + & {
    margin-top: 8px;
  }
}

.ghost-block {
  height: 36px;
  @include background-gradient;
  @include animate-ghost-line;
}