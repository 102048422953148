@import "src/assets/styles/variables";
@import "src/assets/styles/mixins/mixins";

.mat-mdc-dialog-surface {
  padding: $content-gap-md;
}

.mdc-dialog__title {
  margin:  0 !important;
  padding: 0 $content-gap !important;

  &::before {
    height: 0 !important;
  }
}

.mdc-dialog__content {
  padding: $content-gap-half $content-gap !important;
  @include customScroll(6px, $brand-primary, $brand-primary);
}

.mat-mdc-dialog-container {
  position: relative;

  .close-btn {
    position: absolute;
    top:      -18px;
    right:    -18px;
    color:    $gray-light;
  }

  .extra-close-btn {
    position: absolute;
    top:      0px;
    right:    0px;
    color:    $gray-light;
  }
}