.rotating {
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

.fade-in-block {
  -webkit-animation: fadeInBlock 150ms ease-out;
  animation:         fadeInBlock 150ms ease-out;
}

.fade-out-block {
  -webkit-animation: fadeOutBlock 150ms ease-out;
  animation:         fadeOutBlock 150ms ease-out;
}

@-webkit-keyframes fadeInBlock {
  0% {
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInBlock {
  0% {
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-webkit-keyframes fadeOutBlock {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes fadeOutBlock {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}
