@import "../variables";

.table {
  width: 100%;

  & > thead {
    background-color: $th-bg;
  }

  & > tbody > tr > td,
  & > tbody > tr > th,
  & > tfoot > tr > td,
  & > tfoot > tr > th,
  & > thead > tr > td,
  & > thead > tr > th {
    padding: 8px;
  }

  & > tbody > tr > td,
  & > tbody > tr > th {
    vertical-align: middle;
  }

  & > thead th {
    color:          $th-color;
    font-weight:    $th-font-weight;
    font-size:      $th-font-size;
    letter-spacing: $th-letter-sp;
    text-align:     left;
    vertical-align: middle;
    min-height:     $th-height;
  }

  & > tbody > tr {
    &:hover {
      background-color: $gray-lightest-bg;
    }
  }

  &.separate {
    border-collapse: separate;
    border-spacing:  1px;
  }
}

table {
  .table-striped {
    & > tbody > tr {
      background-color: white;

      &:nth-child(odd) {
        background-color: $gray-lightest;

        &:hover {
          background-color: $gray-lightest-bg;
        }
      }
    }
  }

  .date-cell {
    font-size: 14px;
  }
}

.table-wrap {
  table {
    font-size:     $font-size-base;
    margin-bottom: $content-gap-quoter;

    & > tbody > tr > td {
      vertical-align: middle;
    }
  }

  &.condensed {
    table {
      & > tbody > tr > td {
        padding: $content-gap-quoter $content-gap-half;
      }

      & > thead > tr > th {
        padding-left:  $content-gap-half;
        padding-right: $content-gap-half;
      }
    }
  }
}
