@use '@angular/material' as mat;

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.snack-bar-overrides(
    (
      button-color: $brand-primary,
    )
  );
}

.ume-snackbar {
  @include mat.snack-bar-overrides(
    (
      container-color: map-get($ume-color-palette, 'blue-100'),
      supporting-text-color: $body-color,
      button-color: $brand-primary,
    )
  );
}
