@use "sass:math";
@import "src/assets/styles/variables";
@import "src/assets/styles/mixins/mixins";

.mat-mdc-tab-group {
  &.ume-vertical-tabs {
    flex-direction: row;

    &.onboarding-vertical-tabs {
      .mat-mdc-tab-label {
        height:  auto;
        padding: #{math.div($content-gap, 4)};
        opacity: .75;

        &.mat-mdc-tab-label-active {
          background-color: $gray-lighter;
          opacity:          .9;
        }
      }
    }

    &.tab-label-left {
      .mat-mdc-tab-label-content {
        justify-content: flex-start;
      }
    }

    .mat-mdc-tab-header {
      border-bottom: none;
      flex:          0 1 15%;
      min-width:     200px;
    }

    .mat-mdc-tab-body-wrapper {
      flex: 1 1 85%;
    }

    .mat-mdc-tab-header-pagination {
      display: none !important;
    }

    .mat-mdc-tab-label-container {
      height:     100%;
      overflow-y: auto;
      @include customScroll(6px, $brand-primary, $brand-primary);
    }

    .mat-mdc-tab-list {
      width: 100%;
    }

    .mat-ink-bar {
      display: none;
    }

    .mat-mdc-tab-label-content {
      width: 100%;
    }

    .mat-mdc-tab-labels {
      flex-direction: column;
    }

    .mat-mdc-tab-label {
      opacity: .75;

      &.mat-mdc-tab-label-active {
        background-color: map-get($ume-color-palette, "blue-100");
        opacity:          .9;
      }
    }

    .mat-ink-bar {
      height: 100%;
      left:   98% !important;
    }
  }
}

.mat-mdc-tab-link-container {
  flex-grow: 0 !important;

  .mat-mdc-tab-link {
    padding:     0 $content-gap;
    font-weight: map-get($font-weight-values, medium);

    .mdc-tab__text-label {
      color:          $black;
      text-transform: uppercase;
    }
  }
}

.mat-mdc-tab-body-content {
  @include customScroll(6px, $brand-primary, $brand-primary);
}

.screen-menu {
  &.mat-mdc-tab-header {

    .mat-mdc-tab-link {
      text-transform: uppercase;
    }

    .mat-mdc-tab-label-active {
      color:            $brand-primary;
      background-color: map-get($ume-color-palette, "blue-100");
    }
  }
}
