@import "src/assets/styles/variables";

.risk-value {
  display:         inline-flex;
  align-items:     center;
  justify-content: center;
  height:          24px;
  border-radius:   12px;
  padding:         8px 20px;

  & > span {
    font-size:   $body-fs;
    font-weight: 500;
  }

  &.red,
  &.assess-cat-high {
    background-color: transparentize($brand-danger, 0.9);
    color:            $brand-danger;
  }

  &.yellow,
  &.assess-cat-medium {
    background-color: transparentize(map-get($ume-color-palette, yellow-500), 0.9);
    color:            map-get($ume-color-palette, yellow-500);
  }

  &.green,
  &.assess-cat-low {
    background-color: transparentize($brand-success, 0.9);
    color:            $brand-success;
  }
}

.assessment-group_metrics {
  display:         flex;
  align-items:     center;
  justify-content: space-between;

  .item {
    margin:    2px;
    min-width: 32px;

    @include screen($xl) {
      margin: 0 $content-gap-quoter;
    }

    & > button {
      color: $gray-light;
    }
  }

  .name {
    color:         $gray-light;
    font-size:     $fs-sm;
    font-weight:   500;
    text-align:    center;
    height:        24px;
    margin-bottom: $content-gap-quoter;

    @include screen($xl) {
      font-size: $body-fs;
    }
  }

  .value {
    text-align: center;
    padding:    $content-gap-quoter 0;

    &.big {
      font-size: $fs-lg;
    }
  }
}

.assess-cat-low {
  background-color: transparentize($brand-success, 0.9);
  color:            $brand-success;
}

.assess-cat-medium {
  background-color: transparentize(map-get($ume-color-palette, yellow-500), 0.9);
  color:            map-get($ume-color-palette, yellow-500);
}

.assess-cat-high {
  background-color: transparentize($brand-danger, 0.9);
  color:            $brand-danger;
}

.assess-cat-invalid {
  color: $brand-inverse;
}