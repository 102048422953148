.mdc-tooltip {
  &[class^="ume-"],
  &[class*="ume-"] {
    font-size: 12px;
  }

  &.ume-tooltip-primary {
    background-color: map-get($ume-color-palette, "blue-100") !important;
  }

  &.ume-tooltip-accent {
    background-color: transparentize($brand-secondary, .2);
  }

  &.ume-tooltip-success {
    background-color: transparentize($brand-success, .2);
  }

  &.ume-tooltip-warn {
    background-color: transparentize($brand-warning, .2);
  }
}
