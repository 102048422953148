@import "src/assets/styles/variables";

.company-oversight-status {
  display:     inline-flex;
  align-items: center;
  font-size:   $body-fs;
  min-width:   70px;
  padding:     $content-gap-half 0;

  & > span {
    border-radius: 50%;
    display:       inline-block;
    width:         $content-gap-half;
    height:        $content-gap-half;
    margin-right:  $content-gap-half;
  }
}