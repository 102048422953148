@use "sass:math";
@import "../variables";

// Media queries
@mixin screen($point) {
  @media screen and (min-width: $point) {
    @content;
  }
}

@mixin screen-max($point) {
  @media screen and (max-width: $point - 1) {
    @content;
  }
}

// flex mixin
@mixin flex($justify-content: center, $align-items: center, $flex-wrap: nowrap) {
  display:         flex;
  flex-wrap:       $flex-wrap;
  justify-content: $justify-content;
  align-items:     $align-items;
}

// custom scrolls
@mixin customScroll($width, $bar-color, $thumb-color) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar:horizontal {
    height: $width*1.5;
  }

  &::-webkit-scrollbar-track {
    background-color: $bar-color;
    border-left:      math.div($width, 3) solid transparent;
    border-right:     math.div($width, 3) solid transparent;
    background-clip:  padding-box;
  }

  &::-webkit-scrollbar-track:horizontal {
    background-color: $bar-color;
    border-top:       math.div($width, 3) solid transparent;
    border-bottom:    math.div($width, 3) solid transparent;
    background-clip:  padding-box;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border-radius:    4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $blue-dark;
  }
}

// mat-icon size
@mixin mat-icon($size) {
  font-size: $size;
  width:     $size;
  height:    $size;
}

@mixin line-clamp($numLines : 1, $lineHeight: 1.4) {
  overflow:           hidden;
  text-overflow:      ellipsis;
  display:            block;
  /* autoprefixer: off */
  display:            -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height:         $numLines * $lineHeight + unquote("em");
}