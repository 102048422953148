@import "src/assets/styles/variables";

p.ume-counterparty {
  position:      relative;
  display:       inline-block;
  overflow:      hidden;
  text-overflow: ellipsis;
  white-space:   nowrap;
  max-width:     280px;

  & > i {
    position: absolute;
    left:     0;
  }

  & > span {
    padding-left: 24px;
  }
}

.pipe-divider {
  position: relative;
  display:  inline-block;
  height:   36px;
  width:    1px;
  padding:  0 8px;

  &::before {
    content:          "";
    position:         absolute;
    top:              0;
    left:             50%;
    width:            1px;
    height:           100%;
    background-color: $gray-lighter;
  }
}