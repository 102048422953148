@import "src/assets/styles/variables";

$md-width: 480px;
$lg-width: 550px;

body {
  div.float-ui-container {
    max-width: $md-width;
    z-index:   $z-index-popup;

    &.larger {
      max-width: $lg-width !important;
    }
  }
}